import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import {aesEncode} from "./aes.js";

// const baseURL = "http://127.0.0.1:8070"
const baseURL = "https://cxh.api.cqyjs.top"

const service = axios.create({
    baseURL: baseURL,
    timeout: 60 * 1000, // request timeout
    withCredentials: false,
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

//加密
service.interceptors.request.use(config => {
    // config.data = Crypt.enc(JSON.stringify(config.data), key);
    const devid = createDevid()
    config.headers['X-DEV-ID'] = devid
    const aesData = JSON.stringify(config.data)
    config.data ={
        payload: aesEncode(devid, aesData)
    }
    return config;
},error => {
   return error
})


// 获取一个16位的随机数为DEVID
function createDevid(){
    return  'xxxxxxxxxxxxxxxx'.replace(/x/g, c => {
        // eslint-disable-next-line unicorn/prefer-math-trunc, no-bitwise
        const r = (Math.random() * 16) | 0
        return r.toString(16)
    })
}

//登录信息
export function doLoginWithout(data){
    return service({
        url: '/web/user/auth/loginWithout',
        method: 'post',
        data: data
    })
}


//首页
export function home(data){
    return service({
        url: '/web/index/main',
        method: 'post',
        data: data
    })
}
//发送验证码
export function sendSms(data){
    return service({
        url: '/sms/send',
        method: 'post',
        data: data
    })
}

//获取业务员信息
export function saleManInfo(jobCode){
    return service({
        url: '/system/user/getSaleManInfo/'+jobCode,
        method: 'get',
    })
}
